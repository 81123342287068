import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
    <div className="footer-left">
      <h3>Contact Us</h3>
      <p>Email: support@checkaroundme.com</p>
      <p>Phone: +234 915 955 8854</p>
      <p>Address: 123 Main St, City, Country</p>
    </div>

    <div className="footer-right">
      <h3>Follow Us</h3>
      <div className="social-icons">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin-in"></i>
        </a>
      </div>
    </div>

</footer>
  );
}

export default Footer;